.user-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.user-nickname {
    font-size: 16px;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 200px;
}

.first, .second {
    height: 60px;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 18px;
}

.first {
    background: linear-gradient(180deg, rgba(19, 97, 179, 0.1) 0%, rgba(208, 202, 249, 0.1) 100%);
}

.second {
    height: 40px;
    cursor: pointer;
    color: rgba(219, 67, 63, 1);
}

.word {
    font-size: 14px;
    margin-left: 10px;
}

.logo {
    display: inline-block;
    width: 32px;
    height: 32px;
}

.nickname {
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
    color: var(--toco-colorTextBase);
    width: 120px;
}

.user-info-popover {
    :global(.ant-popover-inner) {
        padding: 0;
    }
}

.user-basic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.user-self {
    background-color: var(--toco-colorPrimary);
    color: #fff;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    width: 28px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}