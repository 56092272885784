.layout {
    height: 100%;
}


.header-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 16px;
}

.header {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--toco-colorBgBase);
    height: 56px;
    border-bottom: 1px solid var(--toco-colorBorderCustom);
}


.header-right {
    display: flex;
    margin-right: 16px;
    align-items: center;
    gap: 20px;

    .download {
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}

.info {
    margin-left: 16px;
}

.logo {
    /* padding: 0 16px; */
    width: 200px;
}

.top-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.top-menu-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    position: relative;

    &:not(:last-of-type) {
        &::after {
            position: absolute;
            right: 0;
            top: calc("50% - 10px");
            content: "";
            width: 1px;
            height: 20px;
            background-color: rgba(214, 215, 217, 1);
        }
    }
}

.top-menu-item-tab {
    font-weight: 600;
    font-size: 16px;
    color: var(--toco-colorTextSecondary);
    padding: 0 12px;
    cursor: pointer;
}

.top-menu-item-tab-active {
    font-weight: 600;
    font-size: 16px;
    color: var(--toco-colorTextBase);
    padding: 0 12px;
    cursor: pointer;
}

.organization-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.organization-name {
    padding: 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}
.organization-name-word {
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}
.organization-name-choose {
    cursor: pointer;
}

.organization-role {
    font-size: 12px;
    color: #fff;
    background-color: rgba(19, 97, 179, 1);
    border-radius: 28px;
    padding: 0 6px;
    height: 20px;
    white-space: nowrap;
    line-height: 20px;
    margin-right: 8px;
}

.sider {
    padding: 8px;
    border-right: 1px solid var(--toco-colorBorderSecondary);
}
.content {
    padding: 0;
    margin: 0;
}

.layout-menu {
    :global(.ant-menu-sub) {
        background-color: transparent !important;
    }
}

.display-role {
    height: 20px !important;
    line-height: 20px !important;
    margin: 0 !important;
    cursor: default !important;
}

.display-role-word {
    display: inline-block;
    background: linear-gradient(90deg, #8594C3 0%, #8B98C5 55%, #929EC8 100%);
    border-radius: 10px;
    padding: 0 6px;
    color: #fff;
}