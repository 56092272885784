html {
    overflow: hidden;
}

html,
body {
    margin: 0;
    padding: 0;
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

#root {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
}

.ant-btn-link {
    padding: 0;
}

.can-navigate-table {
    .ant-table-row {
        cursor: pointer;
    }
}

.ant-popover-title {
    min-width: 0 !important;
}

.toco-scroll-table {
    .ant-table-body {
        overflow-y: auto !important;
        height: 100vh;
    }
}

.toco-table-container {
    width: 100%;
    height: 100%;

    :global(> div) {
        height: 100%;
        width: 100%;
    }
}

.ellipsis1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.tooltip-text {
    max-width: 200;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #5d6268;
}

.tooltipcol-text {
    max-width: 200;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #5d6268;
}

.toco-upload-preview {
    .ant-upload-list-item-actions {
        a {
            display: none;
        }
    }
}

.ant-pagination .ant-pagination-options-quick-jumper input {
    height: 26px;
}

.ant-table-wrapper .ant-table.ant-table-small {
    font-size: 12px;
}

.logo-image .ant-image-mask {
    display: none;
}


.toco_antd_table div {
    font-size: 12px;
}