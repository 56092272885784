.change-module {
    .current {     
        height: 35px;
        line-height: 35px;
        display: flex;
        padding: 0 4px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
    }

    .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;

        :global(.ant-image) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .logo-container {
        flex: 0;
        width: 20px;
        height: 20px;
    }

    .logo {
        width: 20px;
        height: 20px;
    }

    .title {
        font-size: 14px;
        margin-left: 12px;
        color: var(--toco-colorTextBase);
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
        width: 0;
    }
}

.module-container {
    width: 320px;
}

.module-search {
    padding: 16px;
}

.module-main-container {
    padding: 0;
    max-height: 50vh;
    overflow: auto;
}

.module-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 6px;
    padding: 2px 28px;
    height: 52px;
}

.module-item-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 12px;
    flex: 1;
}

.module-item-active {
    color: var(--toco-colorPrimary);
    background-color: rgba(19, 97, 179, 0.1);
}

.module-item-name {
    margin-bottom: 2px;
    max-width: 190px;
}

.module-item-role {
    font-size: 12px;
    font-weight: 400;
    color: var(--toco-colorTextSecondary);
}

.module-item-root {
    font-size: 12px;
    font-weight: 400;
    color: var(--toco-colorPrimary);
    margin-left: auto;
}

.module-item-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-top: 1px solid var(--toco-colorBorderCustom);
    cursor: pointer;
}

.change-module-popover {
    :global(.ant-popover-inner) {
        padding: 0;
    }

    :global(.ant-popover-title) {
        padding: 12px;
        border-bottom: 1px solid var(--toco-colorBorderCustom);
    }
}
.module-empty {
    text-align: center;
    padding: 24px;
    color: #ADAFB4;
}